<template lang="pug">
div(class='UiForm', :class='{ Primary: primary }')
	div(class='UiForm--Item', v-for='item in items', :key='item')
		input(
			class='UiInput',
			:class='{ Error: formModel[item].error, Animate: animateError }',
			type='text',
			v-model='formModel[item].value',
			:placeholder='formModel[item].placeholder',
			v-maska='formModel[item].mask'
		)
		div(v-if='formModel[item].error', class='UiInput--Error') {{ formModel[item].errorMessage }}

	div(v-if='agree', class='UiForm--Item')
		label(class='UiCheckbox', :class='{ Error: agreeError }', @click='agreeModel = !agreeModel')
			span(class='UiCheckbox--Icon')
				transition(name='fade')
					UiIcon(v-if='agreeModel', name='system/check', size='md')
			span(class='UiCheckbox--Text')
				| Я согласен с
				|
				NuxtLink(to='/privacy') политикой конфиденциальности
		div(class='UiInput--Error') {{ agreeError }}
</template>

<script lang="ts" setup>
import { feedbackService } from '~/services/feedback';

const router = useRouter();
const { formModel } = useForm();

const animateError = ref<boolean>(false);

/**
 * Define props
 */
const {
	items = ['name', 'phone'],
	source = null,
	subject = 'Заявка с сайта',
	agree = false,
	primary = false,
	target = null,
} = defineProps<{
	items: ('name' | 'email' | 'phone')[];
	source?: string | null;
	subject?: string;
	agree?: boolean;
	primary?: boolean;
	target?: string | null;
}>();

/**
 * Agree model
 */
const agreeModel = ref<boolean>(false);
const agreeError = ref<string | null>(null);

/**
 * Has error
 */
const hasError = computed(
	() =>
		formModel.value.name.error ||
		formModel.value.email.error ||
		formModel.value.phone.error ||
		(agree && agreeModel.value === false)
);

/**
 * Submit form
 */
const submitForm = async () => {
	const form = formModel.value;

	for (const key in items) {
		const item = items[key];

		if (form[item].value === '') {
			form[item].error = true;
		} else if (item === 'phone' && form[item].value.length !== 18) {
			form[item].error = true;
		} else {
			form[item].error = false;
		}
	}

	if (agree && agreeModel.value === false) {
		agreeError.value = 'Вы должны принять соглашение';
		return;
	} else {
		agreeError.value = null;
	}

	if (hasError.value) {
		animateError.value = true;
		setTimeout(() => {
			animateError.value = false;
		}, 1000);

		return;
	}

	await feedbackService.send(
		source,
		formModel.value.name.value,
		formModel.value.phone.value,
		formModel.value.email.value,
		subject,
		target
	);

	router.push('/thanks');
};

/**
 * Define expose
 */
defineExpose({ formModel, submitForm });
</script>

<style lang="less" scoped>
.UiForm {
	padding: 1rem 0;
	.box(grid; 100%; auto; none; 1rem; 1fr; auto; center; center);

	&--Item {
		.grid(1 span);
		.box(grid; 100%; auto; none; 0.3125rem; 1fr; auto; center; start);
	}
}
.UiInput {
	padding: 1rem 1.25rem;
	border: 0.0625rem fade(@ColorBase, 70%) solid;
	outline: none;
	.box(block; 100%; auto; none);
	.text(@ColorBase; 1em 1.125rem @regular @FontFamily);
	.background(@ColorWhite);
	.border-radius(@BorderRadiusSmall);
	.transition(all; 250ms; 'sine');
	.appearance(none);
	.placeholder(color; fade(@ColorBase, 70%));

	&:focus {
		.box-shadow(0 0.375rem 0.75rem -0.25rem fade(@ColorBase, 20%));
	}

	&.Error {
		border: 0.0625rem fade(@ColorPrimary, 70%) solid;

		&.Animate {
			.animation(shake; 1000ms; sine; 250ms; both);
		}
	}
	&--Error {
		padding: 0 1.25rem;
		.text(@ColorPrimary; 1em 0.875rem @regular);
	}
}
.UiCheckbox {
	.box(grid; auto; auto; none; 1rem; 1fr; auto 1fr; center; start);

	&--Icon {
		border: 0.0625rem fade(@ColorBase, 70%) solid;
		.box(flex; 2rem; 2rem; @ColorWhite; center; center; center; nowrap row);
		.text(@ColorPrimary);
		.border-radius(0.625rem);
	}

	&--Text {
		padding: 0.25rem 0 0 0;
		.text(@ColorBase; 1.2rem 1rem @regular; none; none none);
	}

	&.Error {
		& > .UiCheckbox--Icon {
			border: 0.0625rem fade(@ColorPrimary, 70%) solid;
		}
	}
}

.UiForm.Primary {
	.UiInput {
		border: 0.0625rem fade(@ColorWhite, 70%) solid;
		&--Error {
			.text(@ColorWhite; 1em 0.875rem @regular);
		}
	}
	.UiCheckbox {
		&--Icon {
			border: 0.0625rem fade(@ColorWhite, 70%) solid;
		}
		&--Text {
			.text(@ColorWhite; 1.2rem 1rem @regular; none; none none);
		}
	}
}
</style>
